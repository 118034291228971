import header from './header';

// PLUGINS
import withPlugins from '../../../../utils/with-plugins';
import compats from '../../../../verticals/compats/plugins/HEADER/defs/mobile';
import reviews from '../../../../verticals/reviews/plugins/HEADER/defs/mobile';
import {
  Main,
  PluginCoreMobile as core,
  PluginOficialStoreMobile as officialStore,
} from '../../../../components/header-with-plugins';

export default header((featureName, namespace) =>
  withPlugins(core, compats, officialStore, reviews)({ debugMode: false, featureName, namespace })(Main),
);
