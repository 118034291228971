import { Main, PluginCoreMobile as core } from '../../../../components/header-with-plugins';
import header from './header';

// PLUGINS
import withPlugins from '../../../../utils/with-plugins';
import compats from '../../../../verticals/compats/plugins/HEADER/defs/mobile';
import officialStore from '../../../../components/header-with-plugins/plugins/OFFICIAL_STORE/defs/mobile';
import reviews from '../../../../verticals/reviews/plugins/HEADER/defs/mobile';

const configuredHeader = header((featureName, namespace) =>
  withPlugins(core, compats, officialStore, reviews)({ debugMode: false, featureName, namespace })(Main),
);
export default configuredHeader;
