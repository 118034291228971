import { COMPONENT_TYPES } from '../../../../../../../components/header-with-plugins';
import addElementToList from '../../../../../../../utils/with-plugins/helpers/add-element-to-list';
import { POSITION } from '../../../../../../../utils/with-plugins/helpers/constants';
import replaceElementInList from '../../../../../../../utils/with-plugins/helpers/replace-element-in-list';

const mainMapper = props => {
  const { list, title, title_compats, highlights } = props;

  const compatsTitle = {
    type: COMPONENT_TYPES.COMPATS_TITLE,
    props: {
      title,
      title_compats,
    },
    show: !!title_compats,
  };

  const highlightsList = {
    type: COMPONENT_TYPES.HIGHLIGHTS,
    props: {
      highlights,
    },
    show: !!highlights,
  };
  const listWithHighlights = addElementToList(list, highlightsList, POSITION.BEFORE, COMPONENT_TYPES.BOTTOM_SUBTITLE);

  const listWithCompatsTitle = replaceElementInList(listWithHighlights, compatsTitle, COMPONENT_TYPES.TITLE);
  return {
    list: title_compats ? listWithCompatsTitle : listWithHighlights,
  };
};

export default mainMapper;
