import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'header-octopus';
const HEADER = 'header';
const namespace = 'ui-pdp-header';

const mapStateToProps = ({ components: { [HEADER]: header } }, ownProps) => ({
  ...header,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: 'components.header' }),
  connect(mapStateToProps),
);

const component = getComponent => enhance(getComponent(FEATURE_NAME, namespace));

export default component;
