import { REVIEWS_COMPONENT_TYPES } from '../../../../../constants';

const productClasificationMapper = (props, statics) => {
  const { tag, className, reviews } = props;
  const { namespace } = statics;
  return {
    tag,
    className,
    list: [
      {
        wrapperConfig: {
          tag: 'div',
          props: {
            className: `${namespace}__info`,
          },
        },
        type: REVIEWS_COMPONENT_TYPES.REVIEWS_SUMMARY,
        props: {
          reviews,
        },
        show: !!reviews,
      },
    ],
  };
};

export default productClasificationMapper;
